.facilitySummary {
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  h1 {
    flex: 0 1 auto;
    padding: 0;
    margin: 0 1rem 0 0;
    line-height: 4rem;
  }
  p {
    padding: 0;
    margin: 0;
    line-height: 4rem;
  }
}

.facilityLocation {
  flex: 2 1 auto;
  color: #878e95;
  font-size: 16px;
  margin-top: 4px;
  display: inline-block;
  margin-right: 1em;
  
}

.facilityStatus {
  flex: 0 1 auto;
  position: relative;
  top: -8px;
  
}

@media only screen and (max-width: 450px){
  .facilitySummary{
    flex-direction: column;
  }
  .inputFieldContainer{
    margin-left: 0 !important;
  }
}


.deleteFacilityButton {
  margin: 1;
}

.inputFieldContainer {
  margin-left: auto;
}

.deleteFacilityButton {
  margin: 1;
}
