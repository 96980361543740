@import '../../../style/theme.scss';

.pageContainer {
  min-height: 100%;
  overflow: hidden;
  flex-grow: 1;

  &.unauthenticated {
    .username {
      display: none;
    }

    .header {
      min-height: 48px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      .content {
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        max-width: 1100px;
        padding: 0 10px;
      }
    }
  }

  &.authenticated {
    .menuButton {
      color: #004f71;
    }

    .header {
      background-color: $primary;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    }

    .logo {
      margin-left: 10px;
      > g > path {
        fill: #fff;
      }
    }

    .motto {
      display: none;
    }

    .username {
      margin-left: auto;
      font-weight: bold;
    }
  }
}

.header {
  background-color: #efefef;
  width: 100%;
}

.headerContainer {
  max-width: 1000px;
  padding: 12.5px;
  display: flex;
}

.page {
  padding: 15px 12.5px;
  min-height: 100%;
  overflow: hidden;
}

.pageWrapper {
  width: 100%;
}

.logo {
  height: 24px;
}

.mottoContainer {
  position: relative;
  display: inline-block;
  width: 250px;
}

.motto {
  font-size: 12px;
  word-wrap: none;
  line-height: 19px;
  color: #000000;
  opacity: 0.4;
  position: absolute;
  left: 7px;
  top: -7px;
}

.pageContent {
  flex: 1;
  margin: 15px auto;
  max-width: 1100px;
}
