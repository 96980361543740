.button {
  background-color: #fff;
  display: inline-flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.54);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 1px 0 rgba(0, 0, 0, 0.24);
  padding: 0;
  border-radius: 2px;
  border: 1px solid transparent;
  font-size: 14px;
  font-weight: 500;
  font-family: Roboto, sans-serif;

  &:hover {
    cursor: pointer;
    opacity: 0.9;
  }

  &:active {
    cursor: pointer;
    background-color: #eee;
    color: rgba(0, 0, 0, 0.54);
    opacity: 1;

    .iconContainer {
      background-color: #eee;
    }
  }
}

.content {
  padding: 10px;
  font-weight: 500;
}

.iconContainer {
  background-color: rgb(255, 255, 255);
  padding: 10px;
  border-radius: 2px;
}
