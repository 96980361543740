.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  overflow: auto;
}

.logoutIcon {
  path {
    fill: rgba(0, 0, 0, 0.54);
  }
}
