.textFieldContainer {
  display: flex;
}

.inputFieldContainer {
  display: flex;
  flex-direction: column;
  margin: 45px;
  width: 30%;
}

.sendSmsButton {
  margin-top: 45px;
}

.formContainer {
  display: flex;
  flex-direction: column;
}
