.title {
  font-weight: bold;
  font-size: 20px;
}

.deletionButton {
  margin-top: 45px;
  border-radius: 14px;
  color: white;
}

.deleteField {
  width: 100%;
  margin: 4px;
}

.buttonContainer {
  margin-top: 10px;
}

.deleteInput {
  margin-left: auto;
  display: flex;
  justify-content: center;
}