.title {
  font-weight: bold;
}

.smsButton {
  margin-top: 45px;
  border-radius: 16px;
  color: white;
}

.smsTextField {
  width: 100%;
  margin: 4px;
}

.buttonContainer {
  margin-top: 10px;
}
