.login {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.title {
  text-align: center;
}

.direction {
  color: #878e95;
  font-size: 14px;
  line-height: 19px;
  margin-top: 2em;
}

.loginImage {
  cursor: pointer;
}
