.breadCrumbContainer {
  display: flex;
  align-items: center;
  margin: 10px;
  margin-left: 0;
}

.breadCrumb {
  font-weight: bold;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
}

.link {
  text-decoration: none;
}

.inactive {
  color: #459ead;
}

.active {
  color: #000000;
}

.chevron {
  color: #000000;
}
